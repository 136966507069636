<template>
  <v-container class="px-0">
    <v-card>
      <v-card-text>
        <v-container>
          <validation-observer ref="observer">
            <form @submit.prevent="login">
              <v-row align="center" justify="center">
                <v-col cols="auto">
                  <v-img
                    width="150"
                    :src="require('@/assets/images/cp-app-logo-alt.svg')"
                  />
                </v-col>

                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <input-email
                      v-model="email"
                      :error-messages="errors"
                      :error="errors.length !== 0"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="password"
                    rules="required"
                  >
                    <input-password
                      v-model="password"
                      :error-messages="errors"
                      :error="errors.length !== 0"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    color="primary"
                    block
                    type="submit"
                    :disabled="getApiProcessingStatus"
                    :loading="getApiProcessingStatus"
                  >
                    {{ $t("title_login") }}
                  </v-btn>
                </v-col>

                <v-col cols="12" class="text-center">
                  <router-link to="/forgot-password">
                    {{ $t("forgot_password") }}
                  </router-link>
                </v-col>
              </v-row>
            </form>
          </validation-observer>
        </v-container>
      </v-card-text>
    </v-card>

    <v-row class="mt-4 text-center terms-policy-container">
      <v-col cols="12" class="py-1">
        <router-link to="/privacy-policy">
          {{ $t("privacy_policy") }}
        </router-link>
      </v-col>
      <v-col cols="12" class="py-1">
        <router-link to="/terms-conditions">
          {{ $t("terms_of_service") }}
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InputEmail from "@/components/ui/InputEmail";
import InputPassword from "@/components/ui/InputPassword";
import { mapGetters } from "vuex";

export default {
  name: "LoginPage",
  components: { InputPassword, InputEmail },
  data() {
    return {
      email: "",
      password: ""
    };
  },
  computed: {
    ...mapGetters(["getApiProcessingStatus"])
  },
  methods: {
    login() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        }
        let email = this.email;
        let password = this.password;
        this.$store
          .dispatch("AUTH_REQUEST", { email, password })
          .then(response => {
            if (response.status === 200) {
              this.$router.push("/");
            }
          })
          .catch(err => console.log(err));
      });
    }
  }
};
</script>

<style scoped>
.terms-policy-container {
  font-size: 11px !important;
}
</style>
